import { DynamicDropdownService } from '../../../services/shared/dynamic.dropdown.service';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { LocationService } from '../../../services/location/location.service';
import { PolicyDataService } from '../../../services/shared/policy-data.service';
import { JsonFormFetcherService } from '../../../services/shared/json-form-fetcher.service';
import { NestedMatTreeGenerator } from '../../../services/location/nested-mat-tree-generator';
import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormTabService } from 'form-tab';

import { PolicyService } from '@modules/policy/policy.service';
import { TreeService } from '../../../services/location/tree.service';

import { ActivatedRoute, Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { first, mergeMap } from 'rxjs/operators';
import LocationTabs from '@shared/models/location.model';
import { DeleteNodeDialog } from './dialogs/delete-node-dialog.component';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { FormService } from '@modules/policy/services/shared/form.service'
import { environment } from '@environments/environment';

@Component({
  selector: 'app-location-content',
  templateUrl: './location-content.component.html',
  styleUrls: ['./location-content.component.scss']
})
export class LocationContentComponent implements OnInit, AfterViewInit {

  treeControl = new NestedTreeControl<LocationTabs>(node => node.children);
  dataSource = new MatTreeNestedDataSource<LocationTabs>();
  @ViewChild('tree', { static: false }) tree;
  config: any;
  data: any;
  readOnlyPolicy: BehaviorSubject<boolean>;

  private subTabList;
  private tabConfig;
  private policyID;
  private lob;
  private tabState;
  private treeId;

  readonly LOCATION_BUTTON_ID_PREFIX = 'location-button-';
  readonly FORM_STATUS = {
    valid: 'VALID',
    invalid: 'INVALID'
  }

  hasSingleChild = (_: number, node: LocationTabs): boolean => {
    return node.children != null && node.children.length === 1;
  }

  hasMultipleChildren = (_: number, node: LocationTabs): boolean => {
    return node.children != null && node.children.length > 1;
  }

  constructor(
    private formTabService: FormTabService,
    private policyService: PolicyService,
    private PolicyDataService: PolicyDataService,
    private route: ActivatedRoute,
    private screenGeneratorService: NestedMatTreeGenerator,
    private jsonFormFetcherService: JsonFormFetcherService,
    public dialog: MatDialog,
    private treeService: TreeService,
    private router: Router,
    private LocationService: LocationService,
    private DynamicDropdownService: DynamicDropdownService,
    private FormService: FormService
  ) { }

  ngOnInit() {
    this.readOnlyPolicy = this.PolicyDataService.policyReadOnly
    this.treeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.jsonFormFetcherService.tabsConfigSubject;
    this.policyID = this.PolicyDataService.getPolicyID();
    this.lob = this.PolicyDataService.getLOB();
    combineLatest([stateSubject, configSubject]).pipe(first()).subscribe(res => {
      const [states, config] = res;
      this.config = config;
      this.subTabList = config.tabs.find(tab => tab.tabKey === 'location').lists[0];
      this.loadTabs(config.tabs, states);
    });
    this.LocationService.buildingDescriptionSubject.subscribe(data => {
      this.updateBuildingDescription(data);
    });
  }

  updateBuildingDescription(data: any) {
    const location: any = this.dataSource.data.find(location => location.id == data.locationID);
    if (location) {
      const building = location.children.find(building => building.id == data.formID - 1);
      if (building) {
        building.name = data.name;
      }
    }
  }

  ngAfterViewInit() {
    const data = this.dataSource.data;
    if (data.length === 0) return;
    this.treeId ? this.expandTree(this.treeId) : this.expandTree(data[0].id);
  }

  navigateToFirstForm() {
    const data = this.dataSource.data;
    const form = this.treeService.getFirstAvailForm(this.dataSource);
    if (data.length === 0 || form === null) return;
    this.router.navigateByUrl(form.url);
    this.treeId = form.id;
    this.expandTree(this.treeId);
  }

  navigateToForm(subtabId, locationId) {
    const form = this.treeService.getFormForId(subtabId, locationId, this.dataSource);
    if (!form) return;
    this.treeId = form.id;
    this.router.navigateByUrl(form.url);
    this.expandTree(this.treeId);
  }

  navigateToAvailForm(firstForm?: boolean) {
    if (this.route.children.length == 0 || firstForm) {
      this.navigateToFirstForm();
      return;
    }
    const subtab = this.route.children[0].snapshot.params;
    this.navigateToForm(subtab.formId, subtab.locationId);
  }

  loadDataSource(policyID) {
    const tabID = this.tabState.id;
    this.PolicyDataService.getData(policyID, tabID).subscribe(data => {
      this.treeService.updateNumLocations(data.navigation.locations.length);
      this.PolicyDataService.updateUnderWritingRules(data.opinions);
    });
    const data = this.screenGeneratorService.generateLocations(this.tabConfig, this.tabState, policyID, this.lob);
    this.treeService.updateDataSource(data);
    this.navigateToAvailForm();
  }

  loadTabs(tabs, states) {
    const tab = 'location';
    this.tabState = states[tab];
    this.tabConfig = tabs.find(curTab => curTab.tabKey == tab).lists[0];
    this.loadDataSource(this.policyID);
  }

  saveLocation(form: FormGroup) {
    if (!form) return;
    const formValues = form.getRawValue();
    const body = { ...formValues.RiskAddress };
    const navPoint = this.tabState.id;

    this.LocationService.addLocation(body, this.policyID, navPoint).subscribe(tree => {
      this.PolicyDataService.fetchAndUpdatePolicyStates();
      this.treeService.incrementNumLocations();
      this.addLocationToTree(tree.navigation);
      this.policyService.addControlsFromStateTree(tree.navigation, this.tabState.id);
      const navId = tree.navigation.id;
      const formId = tree.navigation.riskAddress.id;
      this.router.navigateByUrl(`/policy/${this.policyID}/${this.lob}/location/${navId}/risk-address/${formId}`);
      this.expandTree(navId);
    });
  }

  addLocationToTree(newLocation) {
    const data = this.dataSource.data;
    const locationStruct: LocationTabs[] = data ? data : [];
    locationStruct.push(this.screenGeneratorService.appendLocation(newLocation, this.tabConfig, this.policyID, this.lob));
    this.treeService.updateDataSource(locationStruct);
  }

  isFormValid(node): Observable<any> {
    const form = this.policyService.getForm(node.id);
    if (form != null) {
      return of(form.status);
    } else {
      return of((node.state && node.state.state) ? this.FORM_STATUS.valid : this.FORM_STATUS.invalid);
    }
  }

  showAddLocationForm(form: FormGroup, formJson: any) {
    this.DynamicDropdownService.appendSelectValuesToConfig(formJson, this.policyID, this.tabState.id).subscribe(() => {
      const dialogRef = this.dialog.open(DialogForm, {
        data: {
          form: form,
          formJson: formJson
        },
        width: '60%',
        maxWidth: '1500px',
        minWidth: '620px'
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res instanceof FormGroup) {
          this.saveLocation(res);
          return;
        }
      })
    });
  }

  createRiskAddressForm() {
    const subtabConfig = this.subTabList.subTabs.find(subtab => subtab.shortRoute === "risk-address");
    this.jsonFormFetcherService.getConfig(this.policyID, subtabConfig.shortRoute).pipe(
      mergeMap(result => this.formTabService.initForm(JSON.stringify(result), JSON.stringify({}))),
    ).subscribe(res => {
      this.showAddLocationForm(res.form, res.orderedFormJson);
    });
  }

  addToTree(newRisk, locationNavId, tabKey) {
    const data = this.dataSource.data;
    let currentLocationIndex;
    const currentLocation = data.find((location, index) => {
      if (location.id == locationNavId) {
        currentLocationIndex = index;
        return true;
      }
    });
    const risk = { [tabKey]: [newRisk], id: locationNavId };
    const updatedLocations = this.screenGeneratorService.appendToLocation(risk, currentLocation, this.tabConfig, this.policyID, tabKey)
    data[currentLocationIndex] = updatedLocations;
    this.treeService.updateDataSource(data);
  }

  addToLocationFromModal(form: FormGroup, locationId, listName) {
    const allFormValues = form.getRawValue();
    let requestBody = {};
    for (let formKey in allFormValues) {
      requestBody = {
        ...allFormValues[formKey],
        ...requestBody
      }
    }
    const serviceFunctionByListName = {
      dwellings: 'addRisk',
      risks: 'addRisk',
      elevators: 'addElevator',
      outbuildings: 'addFarmBuilding',
      grains: 'addGrains',
      liabilities: 'addLiability',
      scheduleItems: 'addScheduleItem',
      supplements: 'addScheduleItem',
      watercrafts: 'addWatercraft',
      binsAndSilos: 'addBinsSilo'
    };
    this.LocationService[serviceFunctionByListName[listName]](requestBody, this.policyID, locationId)
      .subscribe(risk => {
        this.postAddToLocation(risk, locationId, listName);
      });
  }

  addToLocation(locationId, listName) {
    const serviceFunctionByListName = {
      obf: 'addOutbuilding'
    };
    this.LocationService[serviceFunctionByListName[listName]](null, this.policyID, locationId)
      .subscribe(risk => {
        this.postAddToLocation(risk, locationId, listName);
      });
  }

  postAddToLocation(risk: any, locationId: number, listName: string) {
    this.addToTree(risk.navigation, locationId, listName);
    this.updatePolicyStates();
    this.policyService.addControlsFromStateTree(risk.navigation, locationId);
    if (risk.touched != null) {
      for (let touchedRiskItem of risk.touched) {
        for (let list in touchedRiskItem) {
          if (Array.isArray(touchedRiskItem[list])) {
            touchedRiskItem[list].forEach((item) => {
              this.policyService.addControlsFromStateTree(item, touchedRiskItem.id);
            });
          }
        }
      }
    }
    const location = this.dataSource.data.find(location => location.id === locationId);
    const newItem = location.children.find(child => child.id === risk.navigation.id);
    if (newItem != null && newItem.children != null && newItem.children.length === 1) {
      this.router.navigateByUrl(newItem.fullRoute);
    }
  }

  showDialogForm(form: FormGroup, formJson: any, node, listName: string) {
    this.DynamicDropdownService.appendSelectValuesToConfig(formJson, this.policyID, node.id).subscribe(() => {
      this.dialog.open(DialogForm, {
        data: {
          form: form,
          formJson: formJson
        },
        width: formJson[0].width
      }).afterClosed().subscribe(res => {
        if (res instanceof FormGroup) {
          this.addToLocationFromModal(res, node.id, listName);
          return;
        }
      });
    });
  }

  createDialogForm(node, listName, formName) {
    const subtabConfig = this.subTabList.lists.find(list => list.listName == listName).modal;
    this.jsonFormFetcherService.getConfig(this.policyID, subtabConfig.shortRoute).pipe(
      mergeMap(result => this.formTabService.initForm(JSON.stringify(result), JSON.stringify({}))),
    ).subscribe(res => {
      this.FormService.setForm({
        formid: 0,
        form: res.form,
        orderedFormJson: res.orderedFormJson
      });
      this.showDialogForm(res.form, res.orderedFormJson, node, listName);
    });
  }

  addNode(node) {
    const nodeConfig = this.tabConfig.lists.find(list => list.button === node.name);
    const { listName, modal } = nodeConfig;
    if (modal.formName != null) {
      this.createDialogForm(node, listName, modal.formName);
    } else {
      this.addToLocation(node.id, listName);
    }
  }

  deleteNode(node) {
    const data = this.dataSource.data;
    const { id, type } = node;
    if (type === 'locationParent') {
      this.deleteLocation(data, id);
    } else {
      this.deleteLocationSubEntity(data, id, node.children, type);
    }
  }

  deleteLocation(data, id) {
    const dialogRef = this.dialog.open(DeleteNodeDialog, { data: { id, data: this.tabState.locations } });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) return;
      this.LocationService.deleteLocation(this.policyID, id, this.tabState.id).subscribe(res => {
        this.PolicyDataService.fetchAndUpdatePolicyStates();
        const childNodeIds: number[] = [];
        data.find(item => item.id === id).children.forEach((childItem) => {
          if (childItem.id != id) {
            childNodeIds.push(childItem.id);
          }
        });
        this.treeService.recursiveNodeEliminator(data, id);
        this.treeService.updateDataSource(data);
        this.treeService.decrementNumLocations();
        if (res.navigation.locations.length === 0) {
          this.router.navigateByUrl(`/policy/${this.policyID}/${this.lob}/location`);
        }
        this.policyService.removeControlAndChildren(id, childNodeIds);
        this.navigateToFirstForm();
      })
    });
  }

  deleteLocationSubEntity(data, id, children, type) {
    const dialogRef = this.dialog.open(DeleteNodeDialog, { data: { id: id, data } });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        return;
      }
      const parentId = this.treeService.getParent(data, id);
      const childNodeIds: number[] = [];
      if (children != null) {
        children.forEach((node) => {
          childNodeIds.push(node.id);
        });
      }
      const serviceFunctionByListName = {
        dwellings: 'deleteRisk',
        risks: 'deleteRisk',
        elevators: 'deleteElevator',
        // TODO: Some of my least favourite code of all time
        outbuildings: (environment.TENANT === 'smi' ? 'deleteOutbuilding' : 'deleteFarmBuilding'),
        grains: 'deleteGrain',
        liabilities: 'deleteLiability',
        scheduleItems: 'deleteScheduleItem',
        supplements: 'deleteScheduleItem',
        watercrafts: 'deleteWatercraft',
        binsAndSilos: 'deleteBinsSilo'
      };
      this.LocationService[serviceFunctionByListName[type]](this.policyID, id, parentId)
        .subscribe((res) => {
          this.policyService.removeControlAndChildren(id, childNodeIds);
          this.treeService.recursiveNodeEliminator(data, id);
          this.treeService.updateDataSource(data);
          this.updatePolicyStates();

          if (res.touched != null) {
            for (let touchedRiskItem of res.touched) {
              const childIds = [];
              for (let list in touchedRiskItem) {
                if (Array.isArray(touchedRiskItem[list])) {
                  touchedRiskItem[list].forEach((item) => {
                    childIds.push(item.id);
                  });
                }
              }
              this.policyService.removeControlAndChildren(touchedRiskItem.id, childIds);
            }
          }

          // Special case for outbuildings where another node was removed by removing the last outbuilding
          if (type === 'outbuildings' && res.navigation.obf != null && res.navigation.obf.length === 0) {
            this.rebuildTreeForLocation(parentId, res.navigation);
          }

          // TODO: Only navigate if you were looking at the thing you deleted
          this.navigateToAvailForm(true);
        });
    });
  }

  updatePolicyStates() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.PolicyDataService.updateStates(tree);
      this.tabState = tree.location;
    });
  }

  expandTree(navId) {
    if (!this.tree) return;
    const data = this.dataSource.data;
    this.tree.treeControl.dataNodes = data;
    for (const location of data) {
      if (location.id == navId) {
        this.tree.treeControl.expand(location);
      }
      else {
        for (const subtab of location.children) {
          if (subtab.id == navId) {
            this.tree.treeControl.expand(location);
            this.tree.treeControl.expand(subtab);
          }
        }
      }
    }
  }

  buildNodeIdentifier(node: any, buttonType?: string): string {
    switch (node.type) {
      case 'button':
        return `${this.LOCATION_BUTTON_ID_PREFIX}${node.name.replace(' ', '-').toLowerCase()}-${node.id}`;
      case 'locationParent':
      case 'riskParent':
        return `${this.LOCATION_BUTTON_ID_PREFIX}${node.type}-${node.id}${(buttonType === 'delete' ? '-delete' : '')}`;
      default:
        return node.formName;
    }
  }

  rebuildTreeForLocation(locationId: number, newState: any) {
    const newTree = this.screenGeneratorService.rebuildTree(this.dataSource.data, locationId, newState, this.policyID, this.lob, this.tabConfig);
    this.treeService.updateDataSource(newTree);
  }
}
