import InterestTabs from '@shared/models/interests.model';
import { InterestsService } from './../../../services/interests/interests.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss']
})

export class InterestsComponent {

  constructor() {}

  ngOnInit() {}

}
