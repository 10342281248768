import { JsonFormFetcherService } from '../services/shared/json-form-fetcher.service';
import { PolicyDataService } from '../services/shared/policy-data.service';
import { SpinnerService } from './../../../core/services/spinner.service';
import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { PolicyService } from '@modules/policy/policy.service';
import { ActivatedRoute } from '@angular/router';
import { PolicyState } from '@shared/models/state.model';
import { AssetFetcherService } from '../services/shared/asset-fetcher.service';
import { MatDialog } from '@angular/material';
import { BasicDialogComponent } from '../shared/components/basic-dialog/basic-dialog.component';

@Component({
  selector: 'app-policy-editor',
  templateUrl: './policy-editor.component.html',
  styleUrls: ['./policy-editor.component.scss']
})
export class PolicyEditorComponent implements AfterContentChecked, OnInit {
  tabs = [];
  states: PolicyState;
  policyData;

  constructor(
    private PolicyService: PolicyService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public SpinnerService: SpinnerService,
    private PolicyDataService: PolicyDataService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private assetFetcherService: AssetFetcherService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const { data, params } = this.route.snapshot;
    this.PolicyDataService.updatePolicyID(params.id);
    const { states, config, policyConfig } = data.PolicyEditorData;
    this.states = states;
    this.tabs = config;
    this.PolicyDataService.updateStates(states);
    this.JsonFormFetcherService.updateTabsConfig(config);
    this.PolicyService.initializeForms(states);
    this.PolicyDataService.updateLOB(config.lob)

    const { state: { newPolicy } } = history;
    if (policyConfig) this.PolicyDataService.updatePolicyConfig(policyConfig);
    if (newPolicy && policyConfig.openDisclaimerOnPolicyCreation) {
      this.showDisclaimerDialog();
    }

    this.PolicyDataService.getHeaderData(params.id).subscribe((policyData) => {
      this.PolicyDataService.assignPolicyReadOnly(policyData);
      this.policyData = policyData;
      this.PolicyDataService.updateTransactionType(policyData.PolicyTypeCd);
      this.PolicyDataService.updateProvince(policyData.PolicyProvinceCd || null);
    });
  }

  showDisclaimerDialog() {
    this.assetFetcherService.getAssetFile({
      quoteId: this.PolicyDataService.getPolicyID(),
      name: 'disclaimer-content.html'
    }).subscribe(
      (res) => {
        this.dialog.open(BasicDialogComponent, {
          data: {
            title: 'Disclaimer',
            htmlContent: res.data,
            buttons: [
              {
                label: 'OK',
                action: true
              }
            ]
          }
        });
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.PolicyDataService.updatePolicyConfig(null)
  }
}
