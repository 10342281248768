import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SnackbarService } from '@core/services/snackbar.service';
import { UnderwriterReviewDialogComponent } from '@modules/policy/shared/components/underwriter-review-dialog/underwriter-review-dialog.component';
type Base64File = { fileName: string, base64Content: string, fileSize: number }

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, //Needed for performance, have to manually detect changes
})
export class DocUploadComponent {
  readonly MAX_TOTAL_FILE_SIZE = 3_670_016; // 3.5 MB
  files: Base64File[] = [];

  constructor(
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  get totalFileSizes(): number {
    return this.getObjectSize(this.files)
  }
  
  get totalFileSizesInMB(): string {
    return `${this.bytesToMb(this.getObjectSize(this.files)).toFixed(2)} MB`
  }

  get totalFileSizesPercentage(): number {
    return (this.totalFileSizes / this.MAX_TOTAL_FILE_SIZE) * 100
  }

  get maxTotalFileSizeInMb() {
    return `${this.bytesToMb(this.MAX_TOTAL_FILE_SIZE).toFixed(1)} MB`
  }
  
  get totalFileSizesExceeded(): boolean {
    return this.totalFileSizes >= this.MAX_TOTAL_FILE_SIZE
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.fileDenied()) return

    if (event.dataTransfer?.files) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      this.processFiles(droppedFiles);
    }
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }  

  onFileSelect(event: any) {
    if (this.fileDenied()) return
    const selectedFiles = Array.from(event.target.files);
    this.processFiles(selectedFiles as File[]);
    event.target.value = ''
  }

  processFiles(files: File[]) {
    for (let file of files) {
      if (this.fileDenied(file)) continue
      this.cacheFile(file).then(() => this.cd.detectChanges())
    }
  }

  async cacheFile(file: File): Promise<void> {
    const base64Content = await this.readFileAsBase64(file);
    if (this.fileTooLarge(base64Content)) {
      return this.snackbar.open(`'${file.name}' file size was too large`)
    }
    this.files.push({
      fileName: file.name,
      fileSize: file.size,
      base64Content
    });
  }
  
  readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(String(reader.result).split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  uwReview() {
    this.dialog.open(UnderwriterReviewDialogComponent, { data: { documents: this.files } });
  }

  fileDenied(file?: File) {
    if (this.totalFileSizesExceeded) {
      this.snackbar.open("Total file size has been exceeded")
      return true
    }
    if (file && file.type !== 'application/pdf') {
      this.snackbar.open("Document file needs to be in PDF format")
      return true
    }
    if (file && this.files.some((f) => f.fileName === file.name)) {
      this.snackbar.open(`'${file.name}' already added`)
      return true
    }
    return false
  }

  deleteFile(index: number) {
    this.files = this.files.filter((_, i) => index !== i)
  }

  getFileSizeInMB(file: Base64File) {
    const sizeInMB = this.bytesToMb(this.getObjectSize(file));
    return `${sizeInMB.toFixed(2)} MB`; 
  }

  getObjectSize(obj: any) {
    return new Blob([JSON.stringify(obj)]).size
  }

  fileTooLarge(base64: string) {
    const size = this.getObjectSize(base64)
    return size >= this.MAX_TOTAL_FILE_SIZE
  }

  bytesToMb(bytes: number) {
    return bytes / (1024 * 1024)
  }
}