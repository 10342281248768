import { PolicyDataService } from '../../../services/shared/policy-data.service';
import { JsonFormFetcherService } from '../../../services/shared/json-form-fetcher.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PolicyService } from '@modules/policy/policy.service';
import { FormCanDeactivate } from '@modules/policy/shared/components/form-can-deactivate/form-can-deactivate';
import { combineLatest, Subscription } from 'rxjs';
import { PolicyState } from '@shared/models/state.model';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { DynamicDropdownService } from '@modules/policy/services/shared/dynamic.dropdown.service';
import { FormTabService } from 'form-tab';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { MatDialog } from '@angular/material';
import { mergeMap } from 'rxjs/operators';
import { FormService } from '@modules/policy/services/shared/form.service'
import { PolicyTabService } from '@modules/policy/services/policy/policy-tab.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent extends FormCanDeactivate implements OnInit {

  public formjson: any = {};
  public form: FormGroup;

  subTabList: any[];
  shortRoute: string
  tabConfig: any;
  tabState;
  policyId: string;
  tab: string;
  lob: string
  configSubscription: Subscription;
  readOnlyPolicy: boolean;
  navTreeWidth = '320px'

  constructor(
    private PolicyTabService: PolicyTabService,
    private PolicyService: PolicyService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private PolicyDataService: PolicyDataService,
    private DynamicDropdownService: DynamicDropdownService,
    private FormTabService: FormTabService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService,
    private FormService: FormService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.tab = 'policy';
    const stateSubject = this.PolicyDataService.stateSubject;
    const readOnlySubject = this.PolicyDataService.policyReadOnly;
    const configSubject = this.JsonFormFetcherService.tabsConfigSubject;
    this.policyId = this.PolicyDataService.getPolicyID();
    this.lob = this.PolicyDataService.getLOB();
    this.configSubscription = combineLatest([stateSubject, configSubject, readOnlySubject]).subscribe(data => {
      const [states, config, readOnly] = data;
      this.readOnlyPolicy = readOnly
      const lobModal = config.lobModal;
      this.tabConfig = config.tabs.find(tab => tab.tabKey == this.tab);
      this.loadTabs(config.tabs, states);
      if (lobModal && !states.policy.farmRevenue.state) {
        this.createPolicyAddedModal(this.policyId, config.lobModal.shortRoute, config.lobModal.formName);
      }
    });
  }

  showPolicyCreatedDialog(policyID, form: FormGroup, formJson: any) {
    this.DynamicDropdownService.appendSelectValuesToConfig(formJson, policyID, 1).subscribe(() => {
      const dialogRef = this.dialog.open(DialogForm, {
        data: {
          form: form,
          formJson: formJson
        },
        width: formJson[0].width,
        height: formJson[0].height,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        this.saveModalForms(res, policyID);
      });
    });
  }

  saveModalForms(res, policyID) {
    const farmRevenueIds = {
      nodeState: [this.tabState.farmRevenue.id, true],
      tabState: [this.tabState.id, true]
    };
    this.FormSaveHelper.save(res, farmRevenueIds, this.subTabList, this.SaveService, policyID).subscribe(res => {
      this.PolicyDataService.updateStates(res.states);
    });
  }

  createPolicyAddedModal(policyID, shortRoute, formName) {
    this.JsonFormFetcherService.getConfig(this.policyId, shortRoute).pipe(
      mergeMap(result => this.FormTabService.initForm(JSON.stringify(result), JSON.stringify({}))),
    ).subscribe(res => {
      this.FormService.setForm({
        formid: 0,
        form: res.form,
        orderedFormJson: res.orderedFormJson
      });
      this.showPolicyCreatedDialog(policyID, res.form, res.orderedFormJson);
    });
  }

  loadTabs(tabs, states: PolicyState) {
    this.tabState = states.policy;
    const tabConfig = tabs.find((tab: { tabKey: string; }) => tab.tabKey == this.tab);
    this.subTabList = tabConfig.subTabs;
    this.modifySubtabs(this.policyId, this.tabState);
  }

  modifySubtabs(policyId, tabState) {
    this.subTabList = this.subTabList.map(subtab => {
      return {
        ...subtab,
        formId: tabState[subtab.formName].id,
        fullRoute: subtab.fullRoute.replace(':policyId', policyId),
      }
    });
  }

  isFormValid(formId: number) {
    let result: boolean;
    if (this.PolicyService.getForm(formId)) {
      result = this.PolicyService.getForm(formId).valid;
    }
    return result;
  }

  ngOnDestroy() {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

}
