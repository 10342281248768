import { PolicyholderService } from '@modules/policy/services/policy/policyholder.service';
import { ClaimsService } from './../../../services/claims/claims.service';
import { InterestsService } from './../../../services/interests/interests.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DriverService } from '@modules/policy/services/driver/driver.service';
import { VehicleService } from '@modules/policy/services/vehicle/vehicle.service';

export interface AbstractTreeService {
  insertRouteParams?({ route, ...paramValues }): string;
  handleAddModal?(formName: string, nodeId: string): Promise<any>;
  updateDataSource(data: any): void;
  delete(quoteId: string, parentId: string, nodeId: string): Observable<any>;
  add(quoteId: string, parentId: string, body?: any): Observable<any>;
  dataSourceSubject: BehaviorSubject<any[]>;
}

export const serviceMap: { [k: string]: { new(...args: any[]): AbstractTreeService } } = {
  "interest": InterestsService,
  "claim": ClaimsService,
  "policy": PolicyholderService,
  "vehicle": VehicleService,
  "driver": DriverService
}