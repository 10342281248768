import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AbstractTreeService } from '@modules/policy/shared/components/mat-tree/serviceConfig';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriverService implements AbstractTreeService {
  dataSourceSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  updateDataSource(data: any): void {
    this.dataSourceSubject.next(data);
  }

  delete(quoteId: string, parentId, nodeId): Observable<any> {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/driver`, { params: { quoteId, parentId, nodeId } });
  }

  add(quoteId: string, parentId): Observable<any> {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/driver`, {}, { params: { quoteId, parentId } });
  }

  getAbstractSGI(body, quoteId, nodeId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/sgi/driver-abstract`, body,  { params: { quoteId, nodeId } })
  }

  getClientsByName(DriverFirstNameTxt: string, DriverLastNameTxt: string, DriverAddressPostalTxt: string, quoteId: any) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/contactsByNameZipCode/driver`, {
      params: {
        DriverFirstNameTxt,
        DriverLastNameTxt,
        DriverAddressPostalTxt,
        quoteId
      }
    });
  }
  
  getClientsByZip() {
    return of({})
  }
}
