import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DocUploadGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { extras: { state } } = this.router.getCurrentNavigation();
    
    //Can only navigate to if user is coming from the rate page
    if (!state?.navigation) {
      const { id: policyID, lob } = route.parent?.params ?? {}
      this.router.navigate(['/policy', policyID, lob, 'rating'])
      return false;
    }
    return true;
  }
}
