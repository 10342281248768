import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsonFormFetcherService {
  public tabsConfigSubject: BehaviorSubject<any>;
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  constructor(private http: HttpClient) {
    this.tabsConfigSubject = new BehaviorSubject(null);
  }

  updateTabsConfig(data) {
    this.tabsConfigSubject.next(data);
  }

  getTabs(policyId: string) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/assets`, { params: { quoteId: policyId, name: "tabs" } });
  }

  getConfig(policyId: string, formName: string) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/assets`, { params: { quoteId: policyId, name: formName } });
  }

  getCommonConfig(formName: string) {
    return this.http.get<any>(`${this.config.apiUrl}/assets/common`, { params: { name: formName } });
  }
}
