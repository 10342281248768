import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackbarService } from '@core/services/snackbar.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { VehicleService } from '@modules/policy/services/vehicle/vehicle.service';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { catchError } from 'rxjs/operators';

@Component({
  selector: "app-add-vehicle-dialog",
  templateUrl: "./add-vehicle-dialog.component.html",
  styleUrls: ["./add-vehicle-dialog.component.scss"],
})
export class AddVehicleDialogComponent extends DialogForm implements OnInit {
  readonly PLATE_NUM = "VehiclePlateNumberNum";
  readonly CONSENT_IND = "VehicleLookupConsentInd";
  searchForm: FormGroup;
  vehicleService: VehicleService
  detailsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    dialogRef: MatDialogRef<AddVehicleDialogComponent>,
    changeDetector: ChangeDetectorRef,
    public policyDataService: PolicyDataService,
    private snackbar: SnackbarService
  ) {
    super(data, dialogRef, changeDetector);
    this.vehicleService = data.vehicleService;
    this.detailsForm = data.form;
  }

  ngOnInit() {
    this.searchForm = new FormGroup({
      [this.PLATE_NUM]: new FormControl(null),
      [this.CONSENT_IND]: new FormControl(null),
    });
  }

  showSnackbar(message: string) {
    this.snackbar.open(message);
  }

  getSGI(form: FormGroup) {
    this.searchForm.controls[this.CONSENT_IND].disable(); // prevent user from revoking consent after searching
    this.detailsForm.addControl("VehicleSearch", this.searchForm); // allows saving consent and plate num
    const plateNum = this.searchForm.value[this.PLATE_NUM];
    this.showSnackbar(`Searching for ${plateNum}...`);
    this.vehicleService.getSGI(plateNum)
    .pipe(catchError((err) => {
      this.dialogRef.close()
      return err
    })).subscribe((res) => {
      if (res.data) {
        this.showSnackbar("Vehicle found!");
        for (const [key, value] of Object.entries(res.data)) {
          form.controls['addVehicle']['controls'][key].setValue(value);
        }
      } else {
        this.showSnackbar("Vehicle not found!");
      }
    });
  }

  disableSearch() {
    return !Object.values(this.searchForm?.controls).every(control => control.value)
  }

}
