import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@core/services/snackbar.service';
import { DriverService } from '@modules/policy/services/driver/driver.service';
import { FormService } from '@modules/policy/services/shared/form.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';
import { of } from 'rxjs';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {
  readonly SEARCH_FIELDS = {
    CUSTOMER_NUM: 'DriverCustomerID',
    VALIDATION_NUM: 'DriverValidationNumberNum',
    CONSENT_IND: "DriverLookupConsentInd"
  }
  searchForm = new FormGroup({
    [this.SEARCH_FIELDS.CUSTOMER_NUM]: new FormControl(null),
    [this.SEARCH_FIELDS.VALIDATION_NUM]: new FormControl(null),
    [this.SEARCH_FIELDS.CONSENT_IND]: new FormControl(null)
  });
  form: FormGroup;
  formName: string;
  lob: string
  nodeId
  clientKey = null;
  policyId
  options: ClientSearchOptions;
  disableSearch: boolean = false;
  @ViewChild("formComponent", { static: false }) formComponent: FormComponent;
  
  constructor(
    public policyDataService: PolicyDataService,
    private driverService: DriverService,
    private route: ActivatedRoute,
    private SaveService: SaveService,
    private FormService: FormService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit() {
    this.policyId = Number(this.policyDataService.getPolicyID());
  }

  canDeactivate() {
    return of(true).toPromise();
  }

  saveBeforeDeactivate() {
    if (this.FormService.doesFormExist(Number(this.nodeId))) {
      const formRawValues = this.form?.getRawValue() ?? {};
      return this.form?.dirty || this.form?.disabled ?
        Promise.all([
          this.SaveService.updatePolicy(this.policyId, formRawValues, this.nodeId).toPromise(),
          this.formComponent.saveBeforeDeactivate()
        ]) :
        this.formComponent.saveBeforeDeactivate();
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  initForm(form: FormGroup) {
    this.form = form;
    this.nodeId = Number(this.route.snapshot.params["formId"]);
    this.formName = "DriverInformation"
    this.options = {
      sectionTitle: 'Contact Search',
      objectName: 'contact',
      clientReturnKeys: {
        clientKey: 'DriverContactSearchCd',
        contactReturnKey: 'SearchContactSearchCd',
        addressKey: 'SearchContactAddressPostalTxt',
        nameKey: 'SearchContactNameTxt',
      },
      formFields: {
        formName: this.formName,
        firstNameField: 'DriverFirstNameTxt',
        lastNameField: 'DriverLastNameTxt',
        postalCodeField: 'DriverAddressPostalTxt'
      },
      service: this.driverService
    }
    const cachedForm = this.policyDataService.getValueFromCache(this.nodeId);
    if (cachedForm && (cachedForm[this.options.clientReturnKeys.clientKey] ||cachedForm.DriverStatusCd === "C")) {
      this.clientKey = cachedForm[this.options.clientReturnKeys.clientKey];
    } else {
      this.clientKey = null;
    }
    for (const [fieldName, value] of Object.entries(cachedForm)) { // load driver search data
      if (!this.searchForm.controls[fieldName]) continue
      this.searchForm.controls[fieldName].setValue(value)
      this.searchForm.controls[fieldName].disable();
      this.disableSearch = true;
    }
    if (this.formComponent) handleValidations(this.formComponent.formjson, this.form);
  }

  setClientKey(newClientKey: string) {
    this.clientKey = newClientKey;
    this.formComponent.saveBeforeDeactivate();
  }

  driverSearch() {
    if (this.disableDriverSearch()) return;
    this.searchForm.controls[this.SEARCH_FIELDS.CONSENT_IND].disable(); // prevent user from revoking consent after searching
    this.form.addControl("DriverSearch", this.searchForm); // allows saving consent and plate num
    this.snackbar.open(`Searching for driver...`);
    this.driverService.getAbstractSGI(this.searchForm.getRawValue(), this.policyId, this.nodeId)
      .toPromise().then((res) => {
        if (!res.data) {
          return this.snackbar.open("Driver not found");
        }
        this.snackbar.open("Driver found");
        const infoForm = this.form.controls[this.formName] as FormGroup
        for (const [fieldName, value] of Object.entries(res.data)) {
          if (!infoForm.contains(fieldName)) continue
          infoForm.get(fieldName).setValue(value)
        }
        this.form.markAsDirty()
      })
  }

  disableDriverSearch() {
    return !Object.values(this.searchForm?.controls).every(control => control.value) || this.disableSearch
  }

  save() {
    this.saveBeforeDeactivate().then(res => {
      if (res) this.showSaveSnackbar();
    })
  }
}
