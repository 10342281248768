import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PolicyState } from '@shared/models/state.model';
import { JsonFormFetcherService } from './services/shared/json-form-fetcher.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private forms: { [n: number]: FormGroup } = {};
  private formJson: { [n: number]: string } = {};
  public tabForms: { [s: string]: FormGroup } = {};
  private formPathByNodeId: { [n: number]: number[] } = {};

  constructor(private jsonFormFetcherService: JsonFormFetcherService) { }

  getForm(formId: number) {
    return this.forms[formId];
  }

  setForm(formId: number, form: FormGroup) {
    if (formId != 0) {
      if (this.formPathByNodeId[formId] != null) {
        const formPath = [...this.formPathByNodeId[formId]];
        let parentForm: FormGroup = this.getForm(formPath.shift());
        while (formPath.length > 0) {
          const parentFormId = `${formPath.shift()}`;
          parentForm = parentForm.get(parentFormId) as FormGroup;
        }
        parentForm.setControl(`${formId}`, form);
        this.forms[formId] = form;
      } else {
        this.forms[formId] = form;
      }
    }
  }

  getFormJson(formId: number) {
    return this.formJson[formId];
  }

  setFormJson(formId: number, formJson: any) {
    this.formJson[formId] = formJson;
  }

  addControlsFromStateTree(state: any, parentId: number) {
    const parentForm = this.getForm(parentId);
    let formPath;
    if (this.formPathByNodeId[parentId] == null) {
      formPath = [parentId];
    } else {
      formPath = [...this.formPathByNodeId[parentId], parentId];
    }
    this.setChildFormsRecursive(state, parentForm, formPath, false);
  }

  removeControl(formId: number) {
    const formPath = this.formPathByNodeId[formId];
    if (formPath != null) {
      let parentForm: FormGroup = this.getForm(formPath.shift());
      while (formPath.length > 0) {
        const parentFormId = `${formPath.shift()}`;
        parentForm = parentForm.get(parentFormId) as FormGroup;
      }
      if (parentForm) parentForm.removeControl(`${formId}`);
      this.removeForm(formId);
    }
  }

  removeControlAndChildren(formId: number, childFormIds: number[]) {
    childFormIds.forEach((childId) => {
      this.removeForm(childId);
    });
    this.removeControl(formId);
  }

  removeForm(formId: number) {
    delete this.forms[formId];
    delete this.formPathByNodeId[formId];
  }

  refreshNodes(subTrees: any[], parentId: number) {
    const parentForm = this.getForm(parentId);
    let formPath;
    if (this.formPathByNodeId[parentId] == null) {
      formPath = [parentId];
    } else {
      formPath = [...this.formPathByNodeId[parentId], parentId];
    }
    for (const subTree of subTrees) {
      this.setChildFormsRecursive(subTree, parentForm, formPath, true);
    }
  }

  getTabForms() {
    return this.tabForms;
  }

  clearForms() {
    this.forms = {}
    this.formJson = {};
    this.tabForms = {};
    this.formPathByNodeId = {};
  }

  initializeForms(state: PolicyState) {
    for (let tab in state) {
      const tabState = state[tab];
      const tabId = tabState.id;
      if (typeof tabState === 'object') {
        this.tabForms[tab] = new FormGroup({});
        this.forms[tabId] = this.tabForms[tab];
        for (let subItem in tabState) {
          if (Array.isArray(tabState[subItem])) {
            for (let listItem of tabState[subItem]) {
              this.setChildFormsRecursive(listItem, this.tabForms[tab], [tabId], false);
            }
          } else if (typeof tabState[subItem] === 'object') {
            this.setChildFormsRecursive(tabState[subItem], this.tabForms[tab], [tabId], false);
          }
        }
      }
    }
  }

  mapTabNameToLabel() {
    const { tabs } = this.jsonFormFetcherService.tabsConfigSubject.getValue()
    return tabs.reduce((mappedTabs, { formName, label }) => ({ ...mappedTabs, [formName]: label }), {})
  }

  setChildFormsRecursive(state: any, parentForm: FormGroup, formPath: number[], forceRefresh: boolean) {
    if (forceRefresh && this.forms[state.id] != null) {
      this.removeControl(state.id);
    }

    if (this.forms[state.id] == null) {
      this.forms[state.id] = new FormGroup({});
      if (state.state === false) {
        this.forms[state.id].setErrors({ invalid: true });
      }
      parentForm.addControl(state.id, this.forms[state.id]);
    }
    this.formPathByNodeId[state.id] = formPath;

    for (let subItem in state) {
      if (Array.isArray(state[subItem])) {
        for (let listItem of state[subItem]) {
          this.setChildFormsRecursive(listItem, this.forms[state.id], [...formPath, state.id], forceRefresh);
        }
      } else if (typeof state[subItem] === 'object') {
        this.setChildFormsRecursive(state[subItem], this.forms[state.id], [...formPath, state.id], forceRefresh);
      }
    }
  }

  updateTouchedNodeStates(responseData) {
    if (responseData != null && responseData.data.touched != null) {
      for (let touchedItem of responseData.data.touched) {
        for (let subItem in touchedItem) {
          if (Array.isArray(touchedItem[subItem])) {
            this.refreshNodes(touchedItem[subItem], touchedItem.id);
          } else if (typeof touchedItem[subItem] === 'object') {
            this.refreshNodes([touchedItem[subItem]], touchedItem.id);
          }
        }
      }
    }
  }
}
