import { AbstractControl, Validators } from "@angular/forms";
import { validatorFuncs } from "./validation.factory";
import { defaultValidator } from '../default.validator'

const shouldUpdateValidators = (newValidators: Function[], previousValidators: Function[]) => {
  if (newValidators.length != previousValidators.length) return true
  const prevFuncs = previousValidators.map(func => func.toString())
  
  return !newValidators.every(func => prevFuncs.includes(func.toString()))
}

export const getFieldValidations = (field: any) => {
  const syncValidations = [];
  if (field.validations != null) {
    const func = field.validations.custom.func;
    if (Array.isArray(func)) {
      for (const fn of func) {
        syncValidations.push(validatorFuncs[fn]);
      }
    }
  }
  if (field.type === "year") syncValidations.push(validatorFuncs["validateYearFormat"]);
  else if (field.type === "date") syncValidations.push(validatorFuncs["validateDateFormat"]);
  else if (field.type === "phoneNumber") syncValidations.push(validatorFuncs["validatePhoneNumber"]);
  syncValidations.push(...defaultValidator(field.validations, field.type));
  return syncValidations;
}

export const setRequiredValidation = (requireOnConfig: any, formGroup: any, formJson: Array<any>): { [key: string]: any } | null => {
  let prevState = {};
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { validatorForm, validatorField, conditionalField, values, invertValues } = requireOnConfig;
    const validations = getFieldValidations(formJson.find(f => f.name == validatorForm).parameters.find(field => field.name == validatorField));

    if ((Array.isArray(values) && (values.includes(control.get(conditionalField).value) || (control.get(conditionalField).value == undefined && values.includes(null))) != (invertValues == undefined ? false : invertValues)) ||
      ((!Array.isArray(values) && values != undefined) && (values == control.get(conditionalField).value) != (invertValues == undefined ? false : invertValues)) ||
      (values == undefined && control.get(conditionalField).value == true)) {
      validations.push(Validators.required)
    }

    formGroup[validatorForm].get(validatorField).setValidators(Validators.compose([...validations]));
    const fieldKey = `${validatorForm}-${validatorField}`

    //Need to track this field's previous validator state, so it doesn't get infinitely updated
    if (!(fieldKey in prevState) || (fieldKey in prevState && shouldUpdateValidators(validations, prevState[fieldKey]))) {
      prevState[fieldKey] = validations
      formGroup[validatorForm].get(validatorField).updateValueAndValidity({ emitEvent: false });
    }
    return null;
  }
}
