import { FormControl, FormGroup, Validators, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { PolicyCreationService } from '../../services/policy.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material';
import { MetadataService } from '@core/services/metadata.service'
import { policyModalLob } from '@shared/models/feMetadata.model';

// Display errors even if the field hasn't been touched
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.errors != null;
  }
}

@Component({
  selector: 'app-new-policy-modal',
  templateUrl: './new-policy-modal.component.html',
  styleUrls: ['./new-policy-modal.component.scss']
})
export class NewPolicyModalComponent implements OnInit {
  provinces: any[];
  brokerages: any[];
  brokerage: string;
  modalForm: FormGroup;
  filteredOptions: Observable<string[]>;
  matcher = new MyErrorStateMatcher();
  availableLobs: policyModalLob[];
  readonly FIELDS = {
    lob: 'PolicyLineOfBusinessCd',
    province: 'PolicyProvinceCd',
    broker: 'PolicyBrokerNumberTxt',
    effDate: 'PolicyEffectiveDateDt'
  }

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<NewPolicyModalComponent>,
    private PolicyService: PolicyCreationService,
    private MetadataService: MetadataService,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog
  ) {
    this.brokerages = data;
  }

  ngOnInit() {
    const { lobs, provinces } = this.MetadataService.getMetadata().policyCreationModal;
    this.availableLobs = lobs, this.provinces = provinces
    this.modalForm = new FormGroup({
      [this.FIELDS.province]: new FormControl({ value: '', disabled: true }, [Validators.required]),
      [this.FIELDS.effDate]: new FormControl('', [this.validateEffectiveDate, Validators.required]),
      [this.FIELDS.broker]: new FormControl('', [this.validateBroker, Validators.required]),
      [this.FIELDS.lob]: new FormControl('', Validators.required)
    });
    this.modalForm.markAllAsTouched()
    this.modalForm.patchValue({
      [this.FIELDS.effDate]: formatDate(new Date(), 'yyyy-MM-dd', 'en')
    });
    this.filteredOptions = this.modalForm.valueChanges.pipe(
      startWith(''),
      tap(this._handleProvince.bind(this)),
      map(value => this._filter(value[this.FIELDS.broker]))
    );
  }

  private _filter(value: string): string[] {
    if (typeof value != 'string') return;
    const filterValue = value.toLowerCase();
    return this.brokerages.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  private _handleProvince(formValues) {
    if (!formValues) return;
    const { provinceRequired } = this.MetadataService.getLob(this.modalForm.get(this.FIELDS.lob).value) ?? {}
    const provControl = this.modalForm.get(this.FIELDS.province)
    if (provinceRequired && provControl.disabled) {
      provControl.enable()
      provControl.markAsTouched()
    } else if (!provinceRequired && provControl.enabled) {
      provControl.reset(null, { emitEvent: false })
      provControl.disable()
    }
  }

  getOptionText(option) {
    return option && option.value ? option.value : '';
  }

  validateBroker(policy: FormControl) {
    return (typeof policy.value == 'object') ? null : {
      validateBroker: {
        valid: false
      }
    };
  }

  validateEffectiveDate(control: AbstractControl) {
    const [year, month, day] = control.value.split('-').map(Number);
    const enteredDate = new Date(year, month - 1, day);

    if (isNaN(enteredDate.getTime())) {
      return { message: 'Date must follow YYYY-MM-DD format' };
    }

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (enteredDate <= yesterday) {
      return { message: 'Date must be greater or equal to the current date' };
    }

    return null;
  }

  createPolicy() {
    if (!this.modalForm.valid) return;

    const formValues = this.modalForm.getRawValue();
    const params = this._parseParams(formValues);
    this.dialogRef.close();
    this.PolicyService.createPolicy(params).toPromise().then((pol) => {
      const route = this.buildDefaultEditorRoute(pol.data)
      this.router.navigateByUrl(route, { state: { newPolicy: true } });
    });
  }

  _parseParams(formValues) {
    const [lob, PolicyTypeCd] = this.getFormValue(this.FIELDS.lob).split('-');

    return {
      ...formValues,
      PolicyTypeCd,
      [this.FIELDS.lob]: lob,
      [this.FIELDS.broker]: formValues[this.FIELDS.broker].key,
      [this.FIELDS.effDate]: formatDate(formValues[this.FIELDS.effDate], 'yyyy-MM-dd', 'en')
    };
  }

  toFormattedDate(iso: string) {
    this.modalForm.get(this.FIELDS.effDate).setValue(formatDate(iso, 'yyyy-MM-dd', 'en'));
  }

  getFormValue(field: string): any {
    return this.modalForm.get(field).value;
  }

  buildButtonId(lob: string): string {
    return 'new-policy-option-' + lob;
  }

  lobSelected(lob: policyModalLob): boolean {
    return lob.value === this.getFormValue(this.FIELDS.lob);
  }

  private buildDefaultEditorRoute({ PolicyTypeCd, PolicyID, ClientID, PolicyLineOfBusinessCd }: any) {
    let route = this.MetadataService.getDefaultRoute(PolicyLineOfBusinessCd, PolicyTypeCd);
    const PARAM_VALUES = {
      ':policyId': PolicyID,
      ':clientId': ClientID
    }

    for (const [param, value] of Object.entries(PARAM_VALUES)) {
      if (!route.includes(param)) continue;
      route = route.replace(new RegExp(param, 'ig'), value);
    }

    return route;
  }
}