import { BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import InterestTabs from '@shared/models/interests.model';
import { AbstractTreeService } from '@modules/policy/shared/components/mat-tree/serviceConfig';

@Injectable({
  providedIn: 'root'
})
export class InterestsService implements AbstractTreeService {
  public dataSourceSubject: BehaviorSubject<InterestTabs[]>;
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  constructor(private http: HttpClient) {
    this.dataSourceSubject = new BehaviorSubject([]);
  }

  updateDataSource(data) {
    this.dataSourceSubject.next(data);
  }

  add(quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/interest`, {}, { params: { quoteId, parentId } });
  }

  delete(quoteId, parentId, nodeId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/interest`, { params: { quoteId, parentId, nodeId } });
  }

  getClientsByZip(InterestCompanyNameTxt: string, InterestAddressPostalTxt: string, quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/contactsByNameZipCode/interest`, {
      params: {
        InterestCompanyNameTxt,
        InterestAddressPostalTxt,
        quoteId
      }
    });
  }

  getClientsByName(InterestFirstNameTxt: string, InterestLastNameTxt: string, InterestAddressPostalTxt: string, quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/contactsByNameZipCode/interest`, {
      params: {
        InterestFirstNameTxt,
        InterestLastNameTxt,
        InterestAddressPostalTxt,
        quoteId
      }
    });
  }
}