import { Injectable } from '@angular/core';
import { AbstractTreeService } from '@modules/policy/shared/components/mat-tree/serviceConfig';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DynamicDropdownService } from '../shared/dynamic.dropdown.service';
import { FormService } from '../shared/form.service';
import { FormTabService } from 'form-tab';
import { MatDialog } from '@angular/material';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { JsonFormFetcherService } from '../shared/json-form-fetcher.service';
import { FormGroup } from '@angular/forms';
import { PolicyDataService } from '../shared/policy-data.service';
import { LocationService } from '../location/location.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AddVehicleDialogComponent } from '@modules/policy/components/tabs/vehicles/add-vehicle-dialog/add-vehicle-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class VehicleService implements AbstractTreeService {
  dataSourceSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(
    private dialog: MatDialog,
    private formTabService: FormTabService,
    private FormService: FormService,
    private DynamicDropdownService: DynamicDropdownService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private PolicyDataService: PolicyDataService,
    private http: HttpClient
  ) { }

  updateDataSource(data: any): void {
    this.dataSourceSubject.next(data);
  }

  delete(quoteId: string, parentId: string, nodeId: string): Observable<any> {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/vehicle`, { params: { quoteId, parentId, nodeId } });
  }

  add(quoteId: string, parentId: string, body: any): Observable<any> {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/vehicle`, body, { params: { quoteId, parentId } });
  }

  getSGI(VehiclePlateNumberNum: string): Observable<any> {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/sgi/vehicle-lookup`, { params: { VehiclePlateNumberNum: VehiclePlateNumberNum } });
  }

  insertRouteParams({ route , ...paramValues }): string {
    Object.entries(paramValues).forEach(([param, value]) => {
      if (!route.includes(param)) return
      route = route.replace(param, String(value))
    })
    return route
  }

  async handleAddModal(formName: string, nodeId: string) {
    const policyId = this.PolicyDataService.getPolicyID()
    const { form, formJson } = await this.JsonFormFetcherService.getConfig(policyId, formName).pipe(
      mergeMap(result => this.formTabService.initForm(JSON.stringify(result), JSON.stringify({}))),
    ).toPromise().then(res => {
      this.FormService.setForm({
        formid: 0,
        form: res.form,
        orderedFormJson: res.orderedFormJson
      });
      return { form: res.form, formJson: res.orderedFormJson };
    });
    await this.DynamicDropdownService.appendSelectValuesToConfig(formJson, policyId, nodeId).toPromise()
    
    return this.dialog.open(AddVehicleDialogComponent, {
      data: {
        form: form,
        formJson: formJson,
        vehicleService: this
      },
      width: '60%',
      maxWidth: '1500px',
      minWidth: '620px'
    }).afterClosed().toPromise().then(res => {
      if (res instanceof FormGroup) {
        return Object.values(res.getRawValue() as {}[]).reduce((formVals, f) => ({ ...formVals, ...f }));
      }
    });
  }
}
