import { PolicyService } from '@modules/policy/policy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyState } from './../../../../../shared/models/state.model';
import { JsonFormFetcherService } from './../../../services/shared/json-form-fetcher.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { first } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { AbstractTreeService, serviceMap } from './serviceConfig';

import InterestTabs from '@shared/models/interests.model';
import ClaimTabs from '@shared/models/claims.model';
import PolicyholderTabs from '@shared/models/policyholder.model';
import VehicleTabs from '@shared/models/vehicle.model';

@Component({
  selector: 'app-mat-tree',
  templateUrl: './mat-tree.component.html',
  styleUrls: ['./mat-tree.component.scss']
})
export class MatTreeComponent implements OnInit {
  @Input() type: string;
  @Input() listName: string;
  @Input() configList: Array<any>;
  @Input() treeWidth: string = '240px';

  treeControl = new NestedTreeControl<ClaimTabs | InterestTabs | VehicleTabs>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ClaimTabs | InterestTabs | PolicyholderTabs | VehicleTabs>();
  @ViewChild('tree', { static: false }) tree;

  policyId: string;
  tabState: any;
  listConfig: any;
  addLabel: string;
  nodeId: string;
  service: AbstractTreeService;
  listSubTabFormName: string;
  treeHeight: string | null;
  policyReadOnly: BehaviorSubject<boolean>;
  subs: Subscription[] = []

  hasMultipleChildren = (_: number, node): boolean => {
    return node.children != null && node.children.length > 1;
  }

  constructor(
    private PolicyDataService: PolicyDataService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private PolicyService: PolicyService
  ) { }

  ngOnInit() {
    this.policyReadOnly = this.PolicyDataService.policyReadOnly
    this.treeHeight = this.getDynamicHeight()
    this.policyId = this.PolicyDataService.getPolicyID();
    if (serviceMap[this.type]) {
      this.service = this.injector.get<AbstractTreeService>(serviceMap[this.type]);
    } else {
      throw new Error(`Service not found for 'type' param of '${this.type}'`);
    }
    this.subs.push(
      this.route.parent?.data.subscribe(res => {
        const newStates = res?.PolicyEditorData?.states
        if (!newStates) return
        this.PolicyDataService.updateStates(newStates)
      })
    )
    this.service.dataSourceSubject.subscribe(data => {
      this.dataSource.data = data;
    });
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.tabsConfigSubject;
    combineLatest([stateSubject, configSubject]).pipe(first()).subscribe(res => {
      const [states, config] = res;
      this.loadTabs(config.tabs, states);
    });
  }

  loadTabs(config, states: PolicyState) {
    this.tabState = states[this.type];
    const tabConfig = config.find(curTab => curTab.tabKey === this.type);
    this.listConfig = tabConfig.lists.find(x => x.listName === this.listName);
    this.addLabel = this.listConfig.button;
    this.nodeId = states[this.type].id;
    this.listSubTabFormName = this.listConfig.subTabs[0].formName;
    this.PolicyDataService.getData(this.policyId, this.nodeId).subscribe(res => {
      this.PolicyDataService.updateUnderWritingRules(res.opinions);
      const allListItems = [];
      res.navigation[this.listName].forEach(parentListItem => {
        const dataItem = this.transformItem(parentListItem);
        allListItems.push(dataItem);
      });
      this.service.updateDataSource(allListItems);
      this.handleInitialNodeNavigation();
    });
  }

  async addItem() {
    const { modal } = this.listConfig;
    let defaultData;
    if (modal.type) {
      defaultData = await this.service.handleAddModal(modal.shortRoute, this.nodeId)
      if (!defaultData) return;
    }
    const data: (ClaimTabs | InterestTabs | PolicyholderTabs | VehicleTabs)[] = this.dataSource.data;
    this.service.add(this.policyId, this.nodeId, defaultData ?? {}).subscribe((res) => {
      if (!res) return;
      const newItem = this.transformItem(res.navigation);
      this.PolicyService.addControlsFromStateTree(res.navigation, this.tabState.id);
      this.service.updateDataSource([...data, newItem]);
      this.router.navigateByUrl(newItem.fullRoute);
      this.expandTree(newItem.id);
    });
  }

  deleteItem(node: any) {
    let { parentId } = node;
    this.service.delete(this.policyId, this.nodeId, parentId).subscribe(res => {
      const newDataSource = res.navigation[this.listName].map((item: ClaimTabs | InterestTabs | PolicyholderTabs | VehicleTabs) => {
        return this.transformItem(item);
      });
      this.service.updateDataSource(newDataSource);
      this.PolicyService.removeControlAndChildren(node.parentId, [node.id]);
      this.navigateToFirstItem(); // in case there are nodes left
    });
  }

  transformItem(item: any) {
    let newItem = item[this.listSubTabFormName];
    newItem.parentId = item.id;
    newItem.$name = newItem.$name != null ? newItem.$name : item.$name;
    const { fullRoute: route } = this.listConfig.subTabs.find(subTab => subTab.formName === this.listSubTabFormName);
    newItem.fullRoute = this.buildFullRoute(route, newItem)

    if (this.listConfig.subTabs?.length > 1) {
      newItem.children = this.listConfig.subTabs.map((config) => {
        const child = {
          ...config,
          ...item[config.formName],
          $name: config.label,
          parentId: item.id
        }
        child.fullRoute = this.buildFullRoute(child.fullRoute, child)
        
        return child
      })
    }

    return newItem;
  }

  private buildFullRoute(route: string, navItem) {
    let newRoute = route.replace(':policyId', this.policyId).replace(':formId', String(navItem.id));
    if (this.service.insertRouteParams) {
      newRoute = this.service.insertRouteParams({ route: newRoute, ':vehicleId': navItem.parentId })
    }
    return newRoute;
  }

  isFormValid(node) {
    const form = this.PolicyService.getForm(node.id);
    return (form != null) ? form.valid || form.disabled : node.state;
  }

  canShowDeleteButton(node) {
    if (this.listConfig.subTabs?.length > 1) {
      return node.children != null;
    }
    return true;
  }

  getDynamicHeight() {
    //dynamically sets the tree's height based on how many subtab items are passed from the parent component
    const remainingScreenHeight = 208.5;
    const subtabItemHeight = 45;
    return this.configList ? `calc(100vh - ${remainingScreenHeight + (subtabItemHeight * this.configList.length)}px)` : null;
  }

  ngOnDestroy() {
    this.service.updateDataSource([]);
    this.subs.forEach(sub => sub?.unsubscribe())
  }

  expandTree(navId) {
    if (!this.tree || this.listConfig.subTabs?.length <= 1) return;
    const data = this.dataSource.data;
    this.tree.treeControl.dataNodes = data;
    for (const location of data) {
      if (location.id == navId) {
        this.tree.treeControl.expand(location);
      }
      else {
        for (const subtab of location.children) {
          if (subtab.id == navId) {
            this.tree.treeControl.expand(location);
            this.tree.treeControl.expand(subtab);
          }
        }
      }
    }
  }

  navigateToFirstItem() {
    const data = this.dataSource.data;
    if (this.configList) return;
    if (data.length === 0) {
      const lob = this.PolicyDataService.getLOB();
      this.router.navigateByUrl(`policy/${this.policyId}/${lob}/${this.type}`);
      return;
    }
    const formId = Number(this.router.url.split("/")[this.router.url.split("/").length - 1])
    const node = data.filter(node => node.id == formId)
    if (node.length > 0) {
      this.expandTree(node[0]["id"])
    } else {
      this.expandTree(data[0].id);
      this.router.navigateByUrl(data[0].fullRoute);
    } 
  }

  handleInitialNodeNavigation() {
    const data = this.dataSource.data;
    if (this.configList) return;
    if (data.length === 0) {
      const lob = this.PolicyDataService.getLOB();
      this.router.navigateByUrl(`policy/${this.policyId}/${lob}/${this.type}`);
      return;
    }
    const { snapshot } = this.route.children[0] ?? {}
    const hasNodeId = Boolean(snapshot?.paramMap?.keys?.length)
    if (!hasNodeId) {
      this.expandTree(data[0].id);
      return this.router.navigateByUrl(data[0].fullRoute);
    }
    const [, nodeId] = this.router.url.split("/").filter(route => !isNaN(+route) && route)
    const node = data.find(node => node.parentId === Number(nodeId))
    if (node) {
      this.expandTree(node["id"])
    }
  }

}
