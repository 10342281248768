import { FormGroup } from '@angular/forms';
import { PolicyService } from './../../policy.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  private policyTabs = [];
  readOnlyPolicy: BehaviorSubject<boolean>;
  @Input() tabs;
  @Input() states;

  constructor(
    private route: ActivatedRoute,
    public PolicyService: PolicyService,
    private PolicyDataService: PolicyDataService
  ) { }

  ngOnInit() {
    this.policyTabs = this.tabs["tabs"]
    const policyID = this.PolicyDataService.getPolicyID();
    this._modifyPolicyTabRoutes(policyID);
    this.readOnlyPolicy = this.PolicyDataService.policyReadOnly
  }

  ngAfterViewInit() {
    this.PolicyDataService.stateSubject.subscribe(state => {
      const tabForms = this.PolicyService.getTabForms();
      this.policyTabs.forEach(tab => {
        if (!tabForms[tab.formName]) return;
        const errors = state[tab.formName].state === false ? { invalid: true } : null
        tabForms[tab.formName].setErrors(errors);
      });
    })
  }

  _modifyPolicyTabRoutes(id: string) {
    this.policyTabs = this.policyTabs.filter(tab => tab.tabKey != null).map(pol => {
      return {
        ...pol,
        fullRoute: pol.fullRoute.replace(":policyId", id),
      }
    });
  }

  _isActiveTab(shortRoute: string) {
    return shortRoute == this.route.firstChild.snapshot.routeConfig.path.split('/')[0]
  }
}
