import { FormControl, FormGroup, Validators, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { PolicyCreationService } from '../../services/policy.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material';
import { MetadataService } from '@core/services/metadata.service'
import { DisclaimerDialogComponent } from '../dialogs/disclaimer-dialog/disclaimer-dialog.component';
import { AssetFetcherService } from '@modules/policy/services/shared/asset-fetcher.service';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { policyModalLob } from '@shared/models/feMetadata.model';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';

// Display errors even if the field hasn't been touched
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.errors != null;
  }
}

@Component({
  selector: 'app-new-policy-modal',
  templateUrl: './new-policy-modal.component.html',
  styleUrls: ['./new-policy-modal.component.scss']
})
export class NewPolicyModalComponent implements OnInit {
  displayedColumns: string[] = ['name', 'brokerage', 'numPolicy'];
  brokerages: any[];
  brokerage: string;
  modalForm: FormGroup;
  filteredOptions: Observable<string[]>;
  matcher = new MyErrorStateMatcher();
  availableLobs: policyModalLob[];

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<NewPolicyModalComponent>,
    private PolicyService: PolicyCreationService,
    private PolicyDataService: PolicyDataService,
    private MetadataService: MetadataService,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    private AssetFetcherService: AssetFetcherService
  ) {
    this.brokerages = data;
  }

  ngOnInit() {
    this.availableLobs = this.MetadataService.getMetadata().policyCreationModal.lobs;
    this.modalForm = new FormGroup({
      PolicyEffectiveDateDt: new FormControl('', [this.validateEffectiveDate, Validators.required]),
      PolicyBrokerNumberTxt: new FormControl('', this.validateBroker),
      PolicyLineOfBusiness: new FormControl('', Validators.required)
    });
    this.modalForm.patchValue({
      PolicyEffectiveDateDt: formatDate(new Date(), 'yyyy-MM-dd', 'en')
    });
    this.filteredOptions = this.modalForm.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value["PolicyBrokerNumberTxt"]))
    );
  }

  private _filter(value: string): string[] {
    if (typeof value != 'string') return;
    const filterValue = value.toLowerCase();
    return this.brokerages.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  getOptionText(option) {
    return option && option.value ? option.value : '';
  }

  validateBroker(policy: FormControl) {
    return (typeof policy.value == 'object') ? null : {
      validateBroker: {
        valid: false
      }
    };
  }

  validateEffectiveDate(control: AbstractControl) {
    const [year, month, day] = control.value.split('-').map(Number);
    const enteredDate = new Date(year, month - 1, day);

    if (isNaN(enteredDate.getTime())) {
      return { message: 'Date must follow YYYY-MM-DD format' };
    }

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (enteredDate <= yesterday) {
      return { message: 'Date must be greater or equal to the current date' };
    }

    return null;
  }

  createPolicy() {
    if (!this.modalForm.valid) return;

    const formValues = this.modalForm.getRawValue();
    const params = this._parseParams(formValues);
    this.dialogRef.close();
    this.PolicyService.createPolicy(params).toPromise().then((pol) => {
      const { PolicyID, ClientID } = pol.data;
      const { PolicyTypeCd } = params;

      const selectedLob = this.availableLobs.find(lob => {
        return lob.value === this.getFormValue('PolicyLineOfBusiness');
      }).lob;
      const route = this.MetadataService.getDefaultRoute(selectedLob, PolicyTypeCd);
      this.router.navigateByUrl(
        route.replace(/:policyId/ig, PolicyID).replace(/:clientId/ig, ClientID),
        { state: { newPolicy: true } }
      );
    });
  }

  _parseParams(formValues) {
    const [PolicyLineOfBusinessCd, PolicyTypeCd] = this.getFormValue('PolicyLineOfBusiness').split('-');

    return {
      PolicyLineOfBusinessCd,
      PolicyTypeCd,
      PolicyBrokerNumberTxt: formValues.PolicyBrokerNumberTxt.key,
      PolicyEffectiveDateDt: formatDate(formValues.PolicyEffectiveDateDt, 'yyyy-MM-dd', 'en')
    };
  }

  toFormattedDate(iso: string) {
    this.modalForm.get('PolicyEffectiveDateDt').setValue(formatDate(iso, 'yyyy-MM-dd', 'en'));
  }

  getFormValue(field: string): any {
    return this.modalForm.get(field).value;
  }

  buildButtonId(lob: string): string {
    return 'new-policy-option-' + lob;
  }

  lobSelected(lob: policyModalLob): boolean {
    return lob.value === this.getFormValue('PolicyLineOfBusiness');
  }
}