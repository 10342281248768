import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PolicyService } from '@modules/policy/policy.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-driver-assignment',
  templateUrl: './driver-assignment.component.html',
  styleUrls: ['./driver-assignment.component.scss']
})
export class DriverAssignmentComponent implements OnInit {
  readonly TAB_NAME = 'driverAssignment'
  readonly CONFIG = {
    NAME: { COLUMN: 'Vehicle Name', FIELD: 'DriverAssignmentDescriptionTxt'},
    OWNER: { COLUMN: 'Registered Owner', FIELD: 'RegisteredOwnerDriverID'},
    OPERATOR: { COLUMN: 'Primary Operator', FIELD: 'PrimaryOperatorDriverID'}
  }
  private subs: Subscription[] = []
  form: FormGroup;
  rows = new FormArray([])
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = Object.values(this.CONFIG).map(({ COLUMN }) => COLUMN)
  data;
  nodeId;
  lookups: { id: number, label: string }[]

  constructor(
    private PolicyDataService: PolicyDataService,
    private PolicyService: PolicyService,
    private SaveService: SaveService,
    private fb: FormBuilder
  ) { }

  get blockAssignment() {
    return this.lookups?.length === 0 || !this.data?.length
  }

  saveBeforeDeactivate(): Promise<any> {
    if (this.form.disabled) return Promise.resolve()
    const drivers = this.buildSavePayload()
    const policyID = this.PolicyDataService.getPolicyID()
    const payload = [
      { id: this.nodeId, state: this.form.valid }
    ];
    return Promise.all([
      this.SaveService.updateStates(policyID, payload).toPromise(),
      this.SaveService.updatePolicy(policyID, { drivers }, this.nodeId).toPromise()
    ]).then(_ => null)
  }

  ngOnInit() {
    const states = this.PolicyDataService.stateSubject.getValue();
    const { [this.TAB_NAME]: { id: nodeId } } = states
    this.nodeId = nodeId
    this.subs.push(this.PolicyDataService.getData(this.PolicyDataService.getPolicyID(), nodeId)
      .subscribe(({ data, lookups, opinions, ...rest }) => {
        if (opinions) {
          this.PolicyDataService.updateUnderWritingRules(opinions);
        }
        this.data = data.DriverAssignments
        if (this.data) {
          this.form = new FormGroup({ 'DriverAssignments': this.rows });
          this.PolicyService.tabForms[this.TAB_NAME] = this.form
          this.data.forEach((da) => this.addRow(da, true));
          this.lookups = lookups
          this.updateView();
        }
        if (this.lookups.length === 1 && !this.blockAssignment) { //Only one driver assignable
          this.form.disable()
        } else if (this.blockAssignment) { //no drivers assignable
          this.form.setErrors({ invalid: true })
        }
      })
    )
  }

  addRow(da: any, noUpdate?: boolean) {
    const group = {};

    Object.keys(da).forEach(key => {
      group[key] = [da[key], Validators.required];
    });
    const row = this.fb.group(group)
    this.rows.push(row);
    if (!noUpdate) this.updateView();
  }

  updateView() {
    this.dataSource.next(this.rows.controls);
  }

  private buildSavePayload() {
    const { OPERATOR, OWNER } = this.CONFIG
    const [rowForms] = Object.values(this.form.getRawValue())

    const drivers = new Map()

    for (const { VehicleID, ...da } of rowForms as Array<any>) {
      if (da[OPERATOR.FIELD] !== da[OWNER.FIELD]) {
        let DriverID = da[OPERATOR.FIELD]
        if (DriverID) {
          drivers.set(DriverID, [
            ...(drivers.get(DriverID) ?? []),
            { DriverID, VehicleID, DriverAssignmentPrimaryOperatorInd: true }
          ])
        }
        
        DriverID = da[OWNER.FIELD]
        if (DriverID) {
          drivers.set(DriverID, [
            ...(drivers.get(DriverID) ?? []),
            { DriverID, VehicleID, DriverAssignmentRegisteredOwnerInd: true }
          ])
        }
      } else {
        const DriverID = da[OPERATOR.FIELD] ?? da[OWNER.FIELD]
        if (!DriverID) continue
        drivers.set(DriverID, [
          ...(drivers.get(DriverID) ?? []),
          { DriverID, VehicleID, DriverAssignmentPrimaryOperatorInd: true, DriverAssignmentRegisteredOwnerInd: true }
        ])
      }
    }
    return Array.from(drivers.entries()).map(([DriverID, DriverAssignments]) => ({ DriverID, DriverAssignments }))
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }
}
