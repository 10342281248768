import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) { }

  open(message: string, config: MatSnackBarConfig = { duration: 2000 }) {
    this.snackbar.open(message, null, config);
  }
}
