import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '@modules/policy/services/driver/driver.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {
  dataSub: Subscription;

  constructor(
    private driverService: DriverService, 
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.dataSub = this.driverService.dataSourceSubject.pipe(
      takeWhile(res => res.length === 0, true)
    ).subscribe((drivers) => {
      const { formId } = this.route.firstChild?.snapshot?.params ?? {}
      if (drivers.length > 0 && !formId) this.router.navigateByUrl(drivers[0].fullRoute);
    });
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe()
  }
}
