import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PDFData } from '@shared/models/pdf-generator.model';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor() { }

  generatePdf(pdf: PDFData) {
    const doc = new jsPDF();
    const logoUrl = "../../../../../assets/img/Sandbox_Logo_Boxed_LRG.png";
    const img = new Image();

    img.crossOrigin = "anonymous";
    img.src = logoUrl;

    img.onload = function () {
      doc.addImage(img, "PNG", 10, 10, 70, 26);
      doc.setFontSize(12);
      doc.text("Knight Archer Insurance Ltd (8 Street)", 120, 15);
      doc.text("email@knightarcher.com", 120, 22);

      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.setFont(undefined, "bold")
      doc.rect(10, 50, 190, 40);
      doc.text("Quote Reference #" + pdf.quoteKey, 12, 55);
      doc.setFont(undefined, "normal")
      doc.setFontSize(12);
      doc.text("Policy Effective Date:" + pdf.polEffDt + " Effective Time :" + pdf.effTime, 85, 55, { align: "left" });
      doc.text("Policy Expiry Date : " + pdf.polExpDt + "Expiry Time :" + pdf.expTime, 85, 60, { align: "left" });
      doc.setFont(undefined, "bold")
      doc.setFontSize(16);
      doc.text("Total Estimated Premium", 12, 72);
      doc.text(pdf.totalPrem + "/year", 150, 72, { align: "left" });
      doc.setFont(undefined, "normal")
      doc.setFontSize(12);
      doc.text("Maximum Policy Premium", 12, 80);
      doc.text("Minimum Retained Premium", 12, 85);
      doc.text(pdf.maxPolPrem + "/year", 150, 80, { align: "left" });
      doc.text(pdf.minRetainedPrem + "/year", 150, 85, { align: "left" });

      autoTable(doc, {
        headStyles: { fillColor: [192, 234, 241], textColor: [0, 0, 0] },
        showHead: "firstPage",
        startY: 100,
        head: [["Applicant Details", ""]],
        body: Object.keys(pdf.applicantDetails.question).map(key => [pdf.applicantDetails.question[key] || "", pdf.applicantDetails.answer[key] || ""]),
        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            data.cell.styles.fontSize = 14;
            data.cell.styles.fontStyle = "bold";
          }
        }
      });

      // autoTable(doc, {
      //   showHead: "firstPage",
      //   startY: (doc as any).autoTable.previous.finalY + 10,
      //   head: [["Registered Owner & Principal Operator Details", ""]],
      //   body: Object.keys(pdf.ownerOperator.question).map(key => [pdf.ownerOperator.question[key] || "", pdf.ownerOperator.answer[key] || ""]),
      //   didParseCell: function (data) {
      //     if (data.row.index === 0) {
      //       data.cell.styles.fontSize = 14;
      //       data.cell.styles.fontStyle = "bold";
      //     } else if (data.row.index === 2) {
      //       data.cell.styles.fontSize = 8;
      //     }
      //   }
      // });

      // autoTable(doc, {
      //   startY: (doc as any).autoTable.previous.finalY + 10,
      //   head: [["Vehicle Details", ""]],
      //   body: Object.keys(pdf.vehicleDetails.question).map(key => [pdf.vehicleDetails.question[key] || "", pdf.vehicleDetails.answer[key] || ""]),
      //   didParseCell: function (data) {
      //     if (data.row.index === 0) {
      //       data.cell.styles.fontSize = 14;
      //       data.cell.styles.fontStyle = "bold";
      //     } else if (data.row.index === 2) {
      //       data.cell.styles.fontSize = 8;
      //     }
      //   }
      // });

      // autoTable(doc, {
      //   headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      //   head: [['Driver Assignment', '']],
      //   body: Object.keys(pdf.driverAssignment.question).map(key => [pdf.driverAssignment.question[key] || "", pdf.driverAssignment.answer[key] || ""]),
      //   startY: (doc as any).autoTable.previous.finalY + 10,
      // });

      // autoTable(doc, {
      //   headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      //   head: [['Coverage', 'Deductible/Limit', 'Premium']],
      //   body: c8tableData,
      //   startY: doc.lastAutoTable.finalY + 10,
      // });

      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.setFontSize(10);
      doc.rect(10, (doc as any).autoTable.previous.finalY + 10, 190, 160);
      doc.text(doc.splitTextToSize(pdf.textConsent, 250), 15, (doc as any).autoTable.previous.finalY + 15);

      doc.text("APPLICANT'S SIGNATURE  X  _____________________________    DATE_____________________________ ", 10, (doc as any).autoTable.previous.finalY + 180)
      doc.text("APPLICANT'S SIGNATURE  X  _____________________________    DATE_____________________________ ", 10, (doc as any).autoTable.previous.finalY + 190)
      doc.text("APPLICANT'S SIGNATURE  X  _____________________________    DATE_____________________________ ", 10, (doc as any).autoTable.previous.finalY + 200)
      
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    }
  }
}